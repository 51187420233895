@import "colors";

// Bootstrap variables
$navbar-light-color: $navbar-text-color;
$navbar-light-active-color: $navbar-active-color;
$navbar-light-hover-color: $navbar-hover-color;
$navbar-brand-font-size: 1rem * 1.5 !default;
$navbar-toggler-transition: all .15s ease-in-out;

.navbar-brand {
  color: $navbar-text-color;
}

.navbar-brand:hover {
  color: $navbar-hover-color;
}

.brand-img {
  height: auto;
  width: 56px;
}

.custom-navbar {
  padding: .65rem 0;
  background-color: $navbar-background;
  background-image: url('../imgs/background_pattern.svg');
  background-size: 256px;
  background-repeat: repeat;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.navbar-onscroll {
  background-color: $navbar-onscroll-color;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2), inset 0 -1px 0 rgba(0, 108, 74, 0.15) !important;
}

// Navbar Toggler (hamburger menu)
.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: .5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
  position: relative;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $navbar-toggle-color;
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}


/* State when the navbar is collapsed */
.navbar-toggler.collapsed .top-bar {
  position: absolute;
  top: 0px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  position: absolute;
  top: 10px;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  position: absolute;
  top: 20px;
  transform: rotate(0deg);
}

/* when navigation is clicked */
.navbar-toggler .top-bar {
  top: inherit;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  top: inherit;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  top: inherit;
  transform: rotate(-135deg);
}

/* Color of 3 lines */
.navbar-toggler.collapsed .toggler-icon {
  background: $navbar-toggle-color;
}