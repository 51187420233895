@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');

@import "colors";

$corner-radius: 18px;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $body-background,
  "dark": $dark,
);

// Card
$card-color: $dark;
$card-bg: $secondary;
$card-border-width: 0;
$card-border-radius: $corner-radius;

// Button
$btn-color: $secondary-section-bg;
$btn-border-radius: $corner-radius;
$btn-border-radius-sm: $corner-radius;

// Font amd Text styles
$font-family-base: "Nunito", sans-serif;
$headings-font-family: "Nunito", sans-serif;
$headings-font-style: normal;

// Accordion
$accordion-border-color: $secondary-section-bg;
$accordion-border-radius: $corner-radius;
$accordion-inner-border-radius: $corner-radius;

$accordion-bg: $secondary;
$accordion-button-active-bg: $info;

// Input
$input-border-radius: $corner-radius;

@import "../../node_modules/bootstrap/scss/bootstrap";

$bootstrap-icons-font-dir: "../../node_modules/bootstrap-icons/font/fonts";
@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.css";

@import "navbar";

.bd-gutter {
  --bs-gutter-x: 3rem !important;
}

.secondary-section {
  background-color: $secondary-section-bg;
  background-image: url('../imgs/background_pattern.svg');
  background-size: 256px;
  background-repeat: repeat;
  padding: 3rem;
  color: $secondary-section-color;
}

.app-preview {
  height: auto;
  width: 100%;
  max-width: 500px;
  opacity: 1.0; // For mobile with no hover option, make the opacity always 1.0
  transition: opacity 0.5s ease;

  @media (hover: hover) {
    opacity: 0.8;
  }
}

.app-preview:hover {
  opacity: 1.0;
}

.app-preview-feature {
  height: auto;
  max-width: 310.50px;
}

.rounded-18 {
  border-radius: 18px !important
}

.bd-title {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 800;
}

.bd-lead {
  font-size: calc(1.275rem + .1vw);
  font-weight: 300;
}

.icon {
  font-size: 30px;
  line-height: 1;
  speak: none;
  display: inline-block;
  text-align: center;
  text-decoration: inherit;
}

.card-icon {
  font-size: 24px;
  line-height: 1;
  speak: none;
  display: inline-block;
  text-align: center;
  text-decoration: inherit;
}

html {
  scroll-padding-top: 68px;
}

body {
  background-color: $body-background;
}

.a-link-custom {
  color: $dark;
}

// Recaptcha hovering badge (Fixes this badge going below the contact card)
.grecaptcha-badge {
  z-index: 99999;
}